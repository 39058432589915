import { memo } from "react";
import { ExecutorFunction, useResources } from "resourcerer";

import { CardDownloadButton } from "@/components/Button/CardDownloadButton";
import CambioCard from "@/components/CambioCard";
import BarChart from "@/components/Charts/BarChart";
import { Filter } from "@/components/Filters";

import { ChartColors } from "@/js/constants/cambio";
import useRouter from "@/js/hooks/useRouter";
import { currencyTickFormatter } from "@/js/utils/charts";
import { convertToCsvAndDownload } from "@/js/utils/csvCreation";
import { getCurrencySymbol } from "@/js/utils/currency";

import { DisplayCurrencyEnum } from "@/Api/generated";
import { useAppContext } from "@/layouts/AppLayout/AppContext";
import {
  getDateRangeQueryParams,
  getFiltersQueryParams,
  getSelectedYear,
  getSpaceQueryParam,
  isCampus,
  useDashboardContext,
} from "@/pages/DashboardPage/DashboardContent/utils";

const getResources: ExecutorFunction<
  "utilitySpending",
  {
    organizationId: string;
    spaceId?: number;
    space_token?: string;
    filters: Filter[];
    start_date: string;
    end_date: string;
  }
> = ({ start_date, end_date, spaceId, organizationId, space_token, filters }) => ({
  utilitySpending: {
    params: {
      organization_id: organizationId,
      end_date,
      start_date,
      ...getFiltersQueryParams(filters),
      ...getSpaceQueryParam(space_token, spaceId),
    },
    ...(space_token && !isCampus(space_token) ? { dependsOn: !!spaceId } : {}),
  },
});

export default memo(function MonthlyUtilitySpendCard() {
  const { currency, organizationId, organizationName, featureConfigurations } = useAppContext();
  const { property, dateRange, filters, propertyLoadingStates, spaceId } = useDashboardContext();
  const { space_token } = useRouter().query as { space_token: string };
  const { isLoading, hasInitiallyLoaded, hasErrored, utilitySpendingModel } = useResources(
    getResources,
    {
      organizationId,
      spaceId,
      space_token,
      filters,
      ...getDateRangeQueryParams(
        dateRange,
        featureConfigurations,
        !!featureConfigurations.ORG_LEVEL_ANNUALIZED_DATA_ENABLED,
      ),
    },
  );

  const data =
    utilitySpendingModel.get(
      featureConfigurations.ORG_LEVEL_ANNUALIZED_DATA_ENABLED ? "annual_utility_spending" : (
        "monthly_utility_spending"
      ),
    ) || [];

  return (
    <CambioCard
      title="Utility Spend"
      label={currency || ""}
      hasErrored={hasErrored || propertyLoadingStates.hasErrored}
      isLoading={isLoading || propertyLoadingStates.isLoading}
      actionBar={
        <CardDownloadButton
          name="Utility Spend"
          onClick={() => {
            convertToCsvAndDownload(
              [
                { title: "End Date", key: "date" },
                {
                  title: "Electricity Bill",
                  key: "electricity_spend_amount.amount_minor",
                  processValue: processCurrencyValue,
                },
                {
                  title: "Natural Gas Bill",
                  key: "natural_gas_spend_amount.amount_minor",
                  processValue: processCurrencyValue,
                },
                {
                  title: "Water Bill",
                  key: "water_spend_amount.amount_minor",
                  processValue: processCurrencyValue,
                },

                {
                  title: "Waste Bill",
                  key: "waste_spend_amount.amount_minor",
                  processValue: processCurrencyValue,
                },

                { title: "Unit of Measurement", value: currency },
              ],
              data,
              (property ? property.name : organizationName) + " Utility Spend",
            );
          }}
        />
      }
    >
      {hasInitiallyLoaded ?
        <BarChart
          data={data}
          xAxisKey="date"
          yAxisChartDataFields={[
            {
              key: "natural_gas_spend_amount.amount_minor",
              color: ChartColors.TEAL_DARK,
              name: "Natural Gas",
            },
            {
              key: "electricity_spend_amount.amount_minor",
              color: ChartColors.TEAL_CAMBIO,
              name: "Electricity",
            },
            {
              key: "water_spend_amount.amount_minor",
              color: ChartColors.LIME,
              name: "Water",
            },
            ...(featureConfigurations.ORG_LEVEL_DISTRICT_STEAM_ENABLED ?
              [
                {
                  key: "district_energy_spend_amount.amount_minor",
                  color: ChartColors.LEMON,
                  name: "District Energy",
                },
              ]
            : []),
            {
              key: "waste_spend_amount.amount_minor",
              color: ChartColors.ORANGE,
              name: "Waste",
            },
          ].map((entry) => ({
            ...entry,
            style: (entry) =>
              (
                featureConfigurations.ORG_LEVEL_ANNUALIZED_DATA_ENABLED &&
                entry.date !== getSelectedYear(dateRange)
              ) ?
                { opacity: 0.3 }
              : {},
          }))}
          width="100%"
          transformValue={(value) => value / 100}
          tooltipValueFormatter={(value) =>
            getCurrencySymbol(currency as DisplayCurrencyEnum) + processCurrencyValue(value)
          }
          yAxisTickFormatter={(tick) =>
            currencyTickFormatter(tick, currency as DisplayCurrencyEnum)
          }
        />
      : null}
    </CambioCard>
  );
});

function processCurrencyValue(value: number | null) {
  return (value / 100 || 0).toLocaleString("en-US", { maximumFractionDigits: 2 });
}
