import Button from "@/components/Button";
import Modal from "@/components/Modal";
import Spinner from "@/components/Spinner";

import useModalContext from "@/js/hooks/useModalContext";
import useNotificationContext from "@/js/hooks/useNotificationContext";
import useSaveReducer from "@/js/hooks/useSaveReducer";
import useSidePanelContext from "@/js/hooks/useSidePanelContext";
import { CertificationTypes } from "@/js/models/AggregateCertificationsCollection";

export default function DeleteCertificationModal({
  propertyName,
  propertyToken,
  subPortfolioToken,
  certificationType,
  refetch,
}: {
  propertyName: string;
  propertyToken: string;
  subPortfolioToken: string;
  certificationType: CertificationTypes;
  refetch: () => void;
}) {
  const { end } = useModalContext();
  const [{ isSaving }, saveDispatch] = useSaveReducer();
  const { closePanel } = useSidePanelContext();
  const { notify } = useNotificationContext();

  const onConfirmDelete = () => {
    saveDispatch({ type: "saving" });

    fetch(
      `/api/sub_portfolio/${subPortfolioToken}/property/${propertyToken}/certifications?certification_type=${certificationType}`,
      {
        method: "DELETE",
      },
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Delete request failed");
        }
      })
      .then(() => {
        saveDispatch({ type: "success" });
        refetch();
        end();
        closePanel();
        notify(certificationType + " certification has been removed from this property");
      });
  };

  return (
    <Modal
      title={<>Delete certification</>}
      footer={
        <>
          <Button onClick={end}>Cancel</Button>
          <Button flavor="primary" disabled={isSaving} onClick={onConfirmDelete}>
            Delete certification
          </Button>
        </>
      }
      size="small"
    >
      <div className="relative">
        {isSaving ?
          <Spinner flavor="overlay" />
        : null}
        <p>
          Are you sure you want to remove this certification from <b>{propertyName}</b>?
        </p>
      </div>
    </Modal>
  );
}
