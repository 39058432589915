import { memo, useState } from "react";

import ButtonGroup from "@/components/Button/ButtonGroup";
import { CardDownloadButton } from "@/components/Button/CardDownloadButton";
import CambioCard from "@/components/CambioCard";
import ComposedChart from "@/components/Charts/ComposedChart";

import { ChartColors } from "@/js/constants/cambio";
import useRouter from "@/js/hooks/useRouter";
import { convertToCsvAndDownload } from "@/js/utils/csvCreation";
import { unitFormatter } from "@/js/utils/unitFormatter";

import { UtilityData } from "@/Api/generated";
import { useAppContext } from "@/layouts/AppLayout/AppContext";

import {
  getSelectedYear,
  isCampus,
  PropertyResources,
  useDashboardContext,
  useDashboardResources,
} from "../utils";

export const energyUsageDownloadAction = (
  energyUsageData: any,
  property: any,
  organizationName: string,
  energy_unit: string,
) => {
  convertToCsvAndDownload(
    [
      { title: "End Date", key: "date" },

      {
        title: "Energy Usage",
        key: "usage",
        processValue: (value) => {
          return parseFloat(value).toLocaleString("en-US", { maximumFractionDigits: 1 });
        },
      },

      { title: "Energy Usage Unit of Measurement", value: energy_unit },
      {
        title: "Energy Usage Intensity (EUI)",
        key: "eui",
        processValue: (value) => {
          return parseFloat(value).toLocaleString("en-US", { maximumFractionDigits: 1 });
        },
      },
      { title: "EUI Unit of Measurement", value: energy_unit + " psf" },
    ],
    energyUsageData,
    (property ? property.name : organizationName) + " Energy Usage",
  );
};

enum States {
  TOTAL,
  INTENSITY,
}

export default memo(function EnergyUsageCard() {
  const [view, setView] = useState<States>(States.TOTAL);
  const { space_token } = useRouter().query;
  const { organizationName, featureConfigurations } = useAppContext();
  const { property, dateRange } = useDashboardContext();

  const weatherDataEnabled =
    space_token &&
    // Exclude campuses
    !isCampus(space_token as string) &&
    !featureConfigurations.ORG_LEVEL_ANNUALIZED_DATA_ENABLED;

  const { energyConsumptionModel, isLoading, hasErrored, hasInitiallyLoaded, weatherDataModel } =
    useDashboardResources(
      [
        "property",
        "energyConsumption",
        ...(weatherDataEnabled ? ["weatherData" as PropertyResources] : []),
      ],
      {
        useAnnualizedData: !!featureConfigurations.ORG_LEVEL_ANNUALIZED_DATA_ENABLED,
      },
    );
  const energyUsageIntensityData =
    featureConfigurations.ORG_LEVEL_ANNUALIZED_DATA_ENABLED ?
      energyConsumptionModel.get("annual_eui") || []
    : energyConsumptionModel.get("monthly_eui") || [];
  const energyUsageData =
    featureConfigurations.ORG_LEVEL_ANNUALIZED_DATA_ENABLED ?
      energyConsumptionModel.get("annual_energy_consumption") || []
    : energyConsumptionModel.get("monthly_energy_consumption") || [];
  const energyUnit = unitFormatter(energyConsumptionModel.get("energy_unit"));

  const mapTemperatureData = (data: UtilityData, index: number) => {
    const temperatureData = weatherDataModel?.get("data") || [];
    const matchedTemperature = temperatureData.find((tempData) => tempData.date === data.date);

    return {
      ...data,
      ...(weatherDataEnabled && matchedTemperature ?
        { temperature: matchedTemperature.mean_temperature }
      : {}),
    };
  };

  return (
    <CambioCard
      title="Energy"
      // TODO - get units from backend in future
      label={
        (view === States.TOTAL ? energyUnit : energyUnit + " psf") +
        (weatherDataEnabled ? " and " + weatherDataModel?.get("temperature_unit") || "" : "")
      }
      hasErrored={hasErrored}
      isLoading={isLoading}
      actionBar={
        <>
          <ButtonGroup
            items={[
              { key: States.TOTAL, text: "Total" },
              { key: States.INTENSITY, text: "Intensity" },
            ]}
            selected={view}
            onClick={setView}
          />
          <CardDownloadButton
            name="Energy Usage Intensity"
            onClick={() => {
              energyUsageDownloadAction(
                energyUsageIntensityData.map((item, index) => {
                  return {
                    date: item.date,
                    eui: item.value,
                    usage: energyUsageData[index]?.value,
                  };
                }),
                property,
                organizationName,
                energyUnit,
              );
            }}
          />
        </>
      }
    >
      {hasInitiallyLoaded ?
        <ComposedChart
          data={
            view === States.TOTAL ?
              energyUsageData.map(mapTemperatureData)
            : energyUsageIntensityData.map(mapTemperatureData)
          }
          tooltipLabelKey="date"
          xAxisKey="date"
          yAxisChartDataFields={[
            {
              key: "value",
              color: ChartColors.TEAL_CAMBIO,
              style: (entry) =>
                (
                  featureConfigurations.ORG_LEVEL_ANNUALIZED_DATA_ENABLED &&
                  entry.date !== getSelectedYear(dateRange)
                ) ?
                  { opacity: 0.3 }
                : {},
              name: view === States.TOTAL ? "Energy Usage" : "Energy Usage Intensity",
              type: "bar",
            },
            ...(weatherDataEnabled ?
              [
                {
                  key: "temperature",
                  color: ChartColors.BLUE_NAVY,
                  name: "Temperature",
                  type: "line",
                  strokeWidth: 3,
                  showDot: false,
                },
              ]
            : ([] as any[])),
          ]}
          tooltipValueFormatter={(value) =>
            value?.toLocaleString("en-US", { maximumFractionDigits: 1 }) || "N/A"
          }
          primaryType="bar"
          hasReferenceLine
        />
      : null}
    </CambioCard>
  );
});
