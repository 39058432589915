import React, { useMemo } from "react";
import {
  Bar,
  CartesianGrid,
  Line,
  ComposedChart as RechartsComposedChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { ChartAnimationDurations } from "@/js/constants/charts";
import { ChartDataField } from "@/js/types/charts";
import { getNestedValue } from "@/js/utils/cambio";
import {
  getCartesianGridProps,
  getRechartsTooltipProps,
  getRechartsXAxisStyleProps,
  getRechartsYAxisStyleProps,
  getReferenceLineConfig,
} from "@/js/utils/charts";

import { renderBar } from "../BarChart";
import ChartLegend from "../ChartLegend";

export type ComposedChartDataField = {
  type: "bar" | "line";
  strokeWidth?: number | undefined;
  showDot?: boolean;
} & ChartDataField;

interface ComposedChartProps {
  /** The dataset for the chart, where each element represents a data point. */
  data: Record<string, any>[];
  /** The key from the data items used for x-axis values. */
  xAxisKey: string;
  /** An array of data field configurations, specifying whether each is a bar or line and other display properties. */
  yAxisChartDataFields: ComposedChartDataField[];
  /** The key from the data items used to derive labels for tooltips. Defaults to 'year'. */
  tooltipLabelKey: string;
  /** The height of the chart container in pixels. Defaults to "100%". */
  height?: string | number;
  /** The width of the chart container. Can be a percentage or a pixel value. Defaults to "100%". */
  width?: string | number;
  /** A function to format the Y-axis tick labels. Defaults to formatting numbers as strings in the "en-US" locale. */
  yAxisTickFormatter?: (tick: number) => string;
  /** A function to format the numeric values displayed in tooltips. Defaults to locale string formatting in "en-US". */
  tooltipValueFormatter?: (value: number) => string;
  /** A function to format the labels displayed in tooltips. Defaults to a straightforward string conversion. */
  tooltipLabelFormatter?: (label: string) => string;
  /** Whether to display a legend for the chart. Defaults to true. */
  hasLegend?: boolean;
  /** Determines which chart type uses the left y axis */
  primaryType?: "line" | "bar";
  /** Whether to display a reference line on the chart. Defaults to false. */
  hasReferenceLine?: boolean;
}

/**
 * A Chart that can display both line and bar data on the same chart. Line data is displayed on the left Y axis and the bar data is displayed on the right Y axis.
 * In the current implementation the left axis always display and the right axis only displays if there is bar data.
 */

const ComposedChart = ({
  data,
  xAxisKey,
  yAxisChartDataFields,
  height = "100%",
  width = "100%",
  tooltipLabelKey,
  yAxisTickFormatter = (tick) => tick.toLocaleString("en-US"),
  tooltipValueFormatter = (value) => value.toLocaleString("en-US"),
  tooltipLabelFormatter = (label) => label,
  hasLegend = true,
  primaryType = "line",
  hasReferenceLine = false,
}: ComposedChartProps) => {
  const secondaryType = primaryType === "line" ? "bar" : "line";
  const leftYAxisConfig = useMemo(
    () =>
      getRechartsYAxisStyleProps(
        data,
        (value: number) => value,
        yAxisChartDataFields.filter((dataField) => dataField.type === primaryType),
        yAxisTickFormatter,
      ),
    [JSON.stringify(data), yAxisChartDataFields, tooltipValueFormatter],
  );

  const rightYAxisConfig = useMemo(
    () =>
      getRechartsYAxisStyleProps(
        data,
        (value: number) => value,
        yAxisChartDataFields.filter((dataField) => dataField.type === secondaryType),
        yAxisTickFormatter,
      ),
    [JSON.stringify(data), yAxisChartDataFields, tooltipValueFormatter],
  );

  const xAxisConfig = getRechartsXAxisStyleProps(xAxisKey, tooltipLabelFormatter);

  // This function calculates the combined value of data at each x-axis point and is used for calculating the reference line segment.
  function calculateTotalValues(data: any[], yAxisChartDataFields: ComposedChartDataField[]) {
    const returnMap = data.map((dataItem) => {
      const totalValue = yAxisChartDataFields
        .filter((item) => item.type === "bar")
        .reduce((sum, field) => {
          const value = getNestedValue(dataItem, field.key);

          return sum + (typeof value === "number" ? value : 0);
        }, 0);

      return {
        ...dataItem,
        calculated_total_value: totalValue,
      };
    });

    return returnMap;
  }

  return (
    <div className="Chart ComposedChart">
      <ResponsiveContainer width={width} height={height}>
        <RechartsComposedChart data={data} margin={{ top: 20, bottom: 0 }}>
          <XAxis {...xAxisConfig} />

          <YAxis type="number" yAxisId="left-axis" {...leftYAxisConfig} />

          {yAxisChartDataFields.filter((dataField) => dataField.type === secondaryType).length > 0 ?
            <YAxis yAxisId="right-axis" orientation="right" {...rightYAxisConfig} />
          : null}

          <Tooltip
            {...getRechartsTooltipProps(
              data,
              xAxisKey,
              yAxisChartDataFields,
              tooltipLabelKey,
              tooltipLabelFormatter,
              tooltipValueFormatter,
            )}
          />
          <CartesianGrid {...getCartesianGridProps()} />
          {yAxisChartDataFields.map((field, index) => {
            if (field.type === "line") {
              return (
                <Line
                  type="monotone"
                  key={field.key as string}
                  dataKey={field.key as string}
                  stroke={field.color}
                  strokeWidth={field?.strokeWidth}
                  name={field.name}
                  yAxisId={primaryType === "line" ? "left-axis" : "right-axis"}
                  dot={field?.showDot === false ? false : { fill: field.color }}
                  animationDuration={ChartAnimationDurations.SHORT}
                />
              );
            } else if (field.type === "bar") {
              return (
                <Bar
                  dataKey={(dataObject) => {
                    return getNestedValue(dataObject, field.key) || 0;
                  }}
                  strokeWidth={field?.strokeWidth}
                  fill={field.color}
                  name={field.name}
                  yAxisId={primaryType === "bar" ? "left-axis" : "right-axis"}
                  shape={(props: Record<string, any>) =>
                    renderBar(
                      yAxisChartDataFields.filter((item) => item.type === "bar"),
                      { ...props, index, style: field.style },
                    )
                  }
                  animationDuration={ChartAnimationDurations.SHORT}
                />
              );
            }
          })}

          {hasReferenceLine ?
            <ReferenceLine
              {...getReferenceLineConfig(
                calculateTotalValues(data, yAxisChartDataFields),
                xAxisKey,
                "calculated_total_value",
                primaryType === "bar" ? "left-axis" : "right-axis",
              )}
            />
          : null}
        </RechartsComposedChart>
      </ResponsiveContainer>

      {hasLegend && <ChartLegend chartLegendItems={yAxisChartDataFields} />}
    </div>
  );
};

export default ComposedChart;
