import dayjs from "dayjs";
import { useRouter } from "next/router";
import { useState } from "react";
import { useResources } from "resourcerer";

import Button from "@/components/Button";
import Form, {
  DatePicker,
  FormField,
  FormFieldRow,
  Input,
  Select,
  SubmitButton,
} from "@/components/Form";
import SidePanel from "@/components/SidePanel";

import useModalContext from "@/js/hooks/useModalContext";
import useNotificationContext from "@/js/hooks/useNotificationContext";
import useSidePanelContext from "@/js/hooks/useSidePanelContext";
import { CertificationTypes } from "@/js/models/AggregateCertificationsCollection";

import { ApiService, PropertyCertification } from "@/Api/generated";
import { useAppContext } from "@/layouts/AppLayout/AppContext";

import { getResources } from "../CertificationsCard";
import DeleteCertificationModal from "../DeleteCertificationModal";

export const CertificationsEditDrawer = ({
  certification,
  propertyName,
  isNewCertification,
}: {
  certification: PropertyCertification;
  propertyName: string;
  isNewCertification?: boolean;
}) => {
  const [expiryDate, setExpiryDate] = useState(certification.certification_expiration_date);
  const [certificationDate, setCertificationDate] = useState(certification.last_certification_date);
  const [score, setScore] = useState(certification.score);
  const [status, setStatus] = useState(certification.status);
  const { space_token } = useRouter().query;
  const { organizationToken } = useAppContext();
  const { closePanel } = useSidePanelContext();
  const { refetch } = useResources(getResources, {
    subPortfolioToken: organizationToken,
    propertyToken: space_token as string,
  });
  const { launch } = useModalContext();
  const { notify } = useNotificationContext();

  return (
    <SidePanel
      className="CertificationsEditDrawer"
      size="medium"
      title={
        (isNewCertification ? "Add" : "Edit ") + certification.certification_type + " certification"
      }
      footer={
        <>
          <Button
            flavor="link"
            onClick={() => {
              launch(
                <DeleteCertificationModal
                  propertyName={propertyName}
                  propertyToken={space_token as string}
                  subPortfolioToken={organizationToken}
                  certificationType={certification.certification_type as CertificationTypes}
                  refetch={() => refetch(["certifications"])}
                />,
              );
            }}
          >
            Delete Project
          </Button>
          <SubmitButton flavor="primary" form="certifications-form">
            Save Project
          </SubmitButton>
        </>
      }
    >
      <Form
        onSubmit={() => {
          ApiService.apiSubPortfolioPropertyCertificationsCreate({
            propertyToken: space_token as string,
            subPortfolioToken: organizationToken,
            requestBody: {
              status: status,
              certification_type: certification.certification_type,
              last_certification_date: dayjs(certificationDate).format("YYYY-MM-DD"),
              certification_expiration_date: dayjs(expiryDate).format("YYYY-MM-DD"),
              score: score,
            },
          }).then((value) => {
            refetch(["certifications"]);
            closePanel();
            notify(
              certification.certification_type + " certification has been " + isNewCertification ?
                "added to this property"
              : "updated successfully",
            );
          });
        }}
        id="certifications-form"
      >
        <h3>Certification Details</h3>
        <FormFieldRow>
          <FormField
            name="Points Achieved"
            label={
              certification.certification_type === "LEED" ? "Points Achieved" : "EnergyStar score"
            }
            value={score}
          >
            <Input onChange={setScore} value={score} placeholder="Enter score" />
          </FormField>
          <FormField name="Certification Status" label="Certification Status" value={status}>
            <Select
              block
              onSelect={setStatus}
              options={
                certification.certification_type === "LEED" ?
                  [
                    { display: "Certified", value: "Certified" },
                    { display: "Gold", value: "Gold" },
                    { display: "Silver", value: "Silver" },
                    { display: "Bronze", value: "Bronze" },
                    { display: "Platinum", value: "Platinum" },
                    { display: "In Progress", value: "In Progress" },
                  ]
                : [
                    { display: "Certified", value: "Certified" },
                    { display: "In Progress", value: "In Progress" },
                  ]
              }
              placeholder="select status"
              value={status}
            />
          </FormField>
        </FormFieldRow>
        <FormFieldRow>
          <FormField value={certificationDate} name="Certification date" label="Certification date">
            <DatePicker onChange={setCertificationDate} value={certificationDate} />
          </FormField>
          <FormField name="Expiry Date" value={expiryDate} label="Expiry Date">
            <DatePicker onChange={setExpiryDate} value={expiryDate} />
          </FormField>
        </FormFieldRow>
      </Form>
    </SidePanel>
  );
};
