import type { ReactNode } from "react";

import {
  Cell,
  Label,
  Pie,
  PieChart as RechartsPieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

import MetricValue from "@/components/MetricValue";

import { ChartColors } from "@/js/constants/cambio";
import { ChartAnimationDurations } from "@/js/constants/charts";
import { classnames, getNestedValue } from "@/js/utils/cambio";
import { getRechartsPieTooltipProps } from "@/js/utils/charts";

import ChartKpiTitle from "../ChartKpiTitle";
import ChartLegend from "../ChartLegend";

export const PieChartColors = Object.values(ChartColors);

/**
 * A centered label with a title, a metric, and a subtitle which is used in the middle of a Pie chart.
 * This function uses foreignObject to position the HTML content, which includes title, metric value, and KPI title.
 */
function CenteredLabel({
  viewBox,
  centeredTitle,
  centeredMetric,
  centeredMetricSubtitle,
}: {
  /** The viewBox object provided by Recharts, used to calculate the center of the Pie chart. */
  viewBox?: any;
  /** The title displayed at the top of the centered label. */
  centeredTitle: string;
  /** The main metric value displayed in the center. */
  centeredMetric: string;
  /** The subtitle displayed below the metric value. */
  centeredMetricSubtitle: string;
}) {
  const { cx, cy } = viewBox;
  const width = 150;
  const height = 80;

  return (
    <foreignObject x={cx - width / 2} y={cy - height / 2} width={width} height={height}>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h6>{centeredTitle}</h6>
        <MetricValue size="large">{centeredMetric}</MetricValue>
        <ChartKpiTitle>{centeredMetricSubtitle}</ChartKpiTitle>
      </div>
    </foreignObject>
  );
}

type PieChartColorFlavor = "teal-gray" | "lime-lemon" | "none";
// color of pie chart sections where there is no data / where there is lack of progress (making the pie chart sort of a detailed circular progress bar)
// example in the app: we have a pie chart which breakdowns the energy sources of a portfolio into individual renewable sources which are colored and a gray "black cell" section for non-renewable sources are an other category representing lack of completion.
// Another example is a pie chart that shows the percentage of properties onboarded. The percent onboarded value is colored teal and the remaining percent not onboarded is colored this gray "blank cell color".
const blankCellColor = "#E5E7EB";

/**
 * Generates a color array for pie segments based on specified color flavor and the presence of a blank cell.
 */
export function getColorsForPieChart(
  /** The data array used in the pie chart. */
  data: Record<string, any>[],
  /** The color theme for the chart ('teal-gray', 'lime-lemon', or 'none'). */
  colorFlavor: PieChartColorFlavor,
  /**
   * hasBlankFirstCell: Indicates if the first cell should be blank (colored as blankCellColor).
   * disabled: If true, all pie pieces are rendered gray.
   */
  { hasBlankFirstCell, disabled }: { hasBlankFirstCell?: boolean; disabled?: boolean } = {},
) {
  if (disabled) {
    return data.map(() => blankCellColor);
  }

  if (data.length === 2) {
    switch (colorFlavor) {
      case "teal-gray":
        return [ChartColors.TEAL_CAMBIO, blankCellColor];
      case "lime-lemon":
        return [ChartColors.LIME, ChartColors.LEMON];
      default:
        return [];
    }
  } else {
    return data.map(({ color }, i) =>
      hasBlankFirstCell && i === data.length - 1 ? blankCellColor : color || PieChartColors[i],
    );
  }
}

/**
 * Sorts the data array based on the specified data key and transformation function. Optionally ensures that the first cell is treated as blank.
 *
 */
export function sortData<T extends Record<string, any>>(
  /** The data array to be sorted. */
  data: T[],
  /** The key in the data used for sorting. */
  dataKey: string,
  /** A function to transform the value before sorting. */
  transformValue: (value: any) => any,
  /** Indicates if the first cell should remain unsorted as a blank cell. */
  hasBlankFirstCell: boolean,
): T[] {
  const sorter = (a: T, b: T) =>
    transformValue(getNestedValue(b, dataKey)) - transformValue(getNestedValue(a, dataKey));

  if (data.length > 2) {
    return hasBlankFirstCell ? [...data.slice(1).sort(sorter), data[0]] : data.slice().sort(sorter);
  }

  return data;
}

/**
 * Customizable PieChart component using Recharts library, supporting centered content or simple pie display.
 * It allows for detailed configuration of tooltip and legend formatting, pie segment colors, and data sorting.
 */
export function PieChart<T extends Record<string, any>>({
  data,
  dataKey,
  nameKey = "name",
  nameFormatter = (name) => name,
  transformValue = (value) => value,
  flavor = "has-center-content",
  centeredTitle = "",
  centeredMetric = "",
  centeredMetricSubtitle = "",
  hasBlankFirstCell = false,
  tooltipLabelKey = "",
  tooltipLabelFormatter = (label) => label,
  colorFlavor = "teal-gray",
  tooltipValueFormatter = (value) => value.toLocaleString("en-US", { maximumFractionDigits: 0 }),
  disabled,
  hasLegend = true,
  legendPosition = "bottom",
  showLegendValue = false,
  legendValueKey = "",
  legendValueFormatter = (value) => {
    return String(value);
  },
  sorter,
}: {
  /** When disabled, the chart will show all pie slices as gray */
  disabled?: boolean;
  /** The dataset for the pie chart. */
  data: T[];
  /** The key from the data items used for extracting the pie values. */
  dataKey: string;
  /** The key from the data items used for extracting the label names. */
  nameKey?: string;
  /** Function to format the names used in tooltips and legends. */
  nameFormatter?: (name: string) => string;
  /** Function to transform pie values, used in sorting and displaying. */
  transformValue?: (value: any) => any;
  /** Determines if the pie chart should include centered content. */
  flavor?: "has-center-content" | "no-center-content" | "skinny-no-center-content";
  /** Title for the centered content, if applicable. */
  centeredTitle?: string;
  /** Main metric for the centered content, if applicable. */
  centeredMetric?: string;
  /** Subtitle for the centered content, if applicable. */
  centeredMetricSubtitle?: string;
  /** Indicates if the first cell should be styled as a blank segment (a common requirement in our piechart styles). */
  hasBlankFirstCell?: boolean;
  /** Key for extracting tooltip labels from data objects. Dots can be used to access nested values. */
  tooltipLabelKey?: string;
  /** Function to format the tooltip labels. */
  tooltipLabelFormatter?: (label: string) => string;
  /** Function to format the tooltip values. */
  tooltipValueFormatter?: (value: number, object: any) => ReactNode;
  /** Flag indicating whether a legend should be displayed. */
  hasLegend?: boolean;
  /** The color flavor for the pie chart. /When a pie chart has two data points, the colorFlavor prop will determine the colors of the two values in the pie chart.  */
  colorFlavor?: PieChartColorFlavor;
  /** the position of the legend relative to the chart */
  legendPosition?: "bottom" | "right";
  showLegendValue?: boolean;
  legendValueKey?: string;
  legendValueFormatter?: (value: any) => string;
  /** Optional custom sorter function for sorting the data. */
  sorter?: (a: T, b: T) => number;
}) {
  const total = data.reduce((memo, datum) => memo + (datum[dataKey] || 0), 0);
  const sortedData: { [key: string]: any; percentage: number }[] = (
    sorter ?
      data.slice().sort(sorter)
    : sortData(data, dataKey, transformValue, hasBlankFirstCell)).map((datum) =>
    withPercentage(
      {
        ...datum,
        ...(datum.breakdown ?
          { breakdown: datum.breakdown.map((entry: T) => withPercentage(entry, dataKey, total)) }
        : {}),
      },
      dataKey,
      total,
    ),
  );

  const colors = getColorsForPieChart(sortedData, colorFlavor, { hasBlankFirstCell, disabled });

  return (
    <div className={classnames("Chart PieChart", { [legendPosition]: true })}>
      <ResponsiveContainer width={250} height={250}>
        <RechartsPieChart>
          {!disabled && (
            <Tooltip
              {...getRechartsPieTooltipProps(
                sortedData,
                nameKey,
                dataKey,
                (dataPoint) => {
                  return colors[sortedData.indexOf(dataPoint)];
                },
                tooltipLabelKey,
                tooltipLabelFormatter,
                tooltipValueFormatter,
                nameFormatter,
              )}
            />
          )}

          <Pie
            data={sortedData}
            innerRadius={
              flavor === "has-center-content" ? 90
              : flavor === "skinny-no-center-content" ?
                94
              : 74
            }
            outerRadius={116}
            dataKey={(dataObject) => transformValue(getNestedValue(dataObject, dataKey))}
            fill="rgb(229, 231, 235)"
            startAngle={90}
            endAngle={450}
            nameKey={nameKey}
            animationDuration={ChartAnimationDurations.LONG}
          >
            {colors.map((color, index) => (
              <Cell key={`cell-${index}`} fill={color} />
            ))}

            {flavor === "has-center-content" ?
              <Label
                content={
                  <CenteredLabel
                    centeredTitle={centeredTitle}
                    centeredMetric={centeredMetric}
                    centeredMetricSubtitle={centeredMetricSubtitle}
                  />
                }
                position="center"
              />
            : null}
          </Pie>
        </RechartsPieChart>
      </ResponsiveContainer>
      {hasLegend ?
        <ChartLegend
          chartLegendItems={
            sortedData.length > 2 ?
              [
                ...(hasBlankFirstCell ?
                  [
                    {
                      name: nameFormatter(
                        getNestedValue(sortedData[sortedData.length - 1], nameKey),
                      ),
                      color: colors[colors.length - 1],
                      value:
                        showLegendValue ?
                          legendValueFormatter(
                            getNestedValue(sortedData[sortedData.length - 1], legendValueKey),
                          )
                        : undefined,
                    },
                  ]
                : []),
                ...sortedData
                  .slice(0, hasBlankFirstCell ? sortedData.length - 1 : sortedData.length)

                  .map((dataObject, index) => ({
                    name: nameFormatter(getNestedValue(dataObject, nameKey)),
                    color: colors[index],
                    value:
                      showLegendValue ?
                        legendValueFormatter(getNestedValue(dataObject, legendValueKey))
                      : undefined,
                  })),
              ]
            : sortedData.map((dataObject, index) => ({
                name: nameFormatter(getNestedValue(dataObject, nameKey)),
                color: colors[index],
                value:
                  showLegendValue ?
                    legendValueFormatter(getNestedValue(dataObject, legendValueKey))
                  : undefined,
              }))
          }
          vertical={legendPosition === "right"}
          showValue={showLegendValue}
        />
      : null}
    </div>
  );
}

/**
 * Given a data entry, which could be any number, augments it with a `percentage` property based on
 * the total in the chart.
 */
export function withPercentage(entry: Record<string, any>, key: string, total: number) {
  return {
    ...entry,
    percentage: total ? (entry[key] / total) * 100 : 0,
  };
}
