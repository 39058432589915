import type { LineChartProps } from "@/components/Charts/LineChart";

import dayjs from "dayjs";
import pick from "lodash/pick";
import { useMemo, useState } from "react";
import {
  Area,
  AreaChart as RechartsAreaChart,
  ReferenceArea,
  ResponsiveContainer,
  XAxis,
} from "recharts";

import LineChart from "@/components/Charts/LineChart";

import { ChartColors } from "@/js/constants/cambio";
import { ChartAnimationDurations } from "@/js/constants/charts";
import { ChartDataField } from "@/js/types/charts";

export type ComposedChartDataField = { type: "bar" | "line" } & ChartDataField;

interface WindowChartProps extends LineChartProps {
  /** Number of xAxis data points that the window should comprise of */
  windowLength: number;
  /** In minutes */
  interval?: number;
}

/**
 * A Chart that displays two-in-one: A smaller chart on the bottom that displays a longer range,
 * and a larger chart on top that shows a "detail" view of a smaller window within that range.
 *
 * For now, we're assuming that all x-axes for this chart will be time-based, with intervals on the
 * order of 15 min to 1 hour.
 */

const WindowChart = ({
  data,
  yAxisChartDataFields,
  height = "100%",
  width = "100%",
  windowLength,
  interval = 15,
  xAxisKey = "date",
  ...props
}: WindowChartProps) => {
  // this is a safety check in case we get less data than would fit in a window
  windowLength = Math.min(windowLength, data.length);

  const [windowStartIndex, setWindowStartIndex] = useState(0);

  const dataPointsPerDay = (24 * 60) / interval;
  const GRAY_BACKGROUND = useMemo(
    () => getComputedStyle(document.documentElement).getPropertyValue("--gray-2"),
    [],
  );
  const windowData = data.slice(windowStartIndex, windowStartIndex + windowLength);
  // we get both our reference areas and our x-axis ticks, which should be at the start of each day,
  // alternating, starting at this point
  const firstStartOfDayIndex = windowData.findIndex((entry) =>
    dayjs(entry[xAxisKey]).isSame(dayjs(entry[xAxisKey]).startOf("day")),
  );
  // these are the starting indices of both our ticks and our reference areas
  const referenceIndices = Array.from(
    // this will, say, give us 4 reference areas in a week
    { length: Math.ceil(windowLength / (2 * dataPointsPerDay)) },
    (_, i) => firstStartOfDayIndex + dataPointsPerDay * (2 * i),
  ).filter((index) => index < data.length);

  const onClickReferenceChart = (item: any) => {
    if (item?.activePayload?.[0]?.payload) {
      const clickedIndex = data.findIndex(
        (entry) => entry[xAxisKey] === item.activePayload[0].payload[xAxisKey],
      );

      if (clickedIndex > windowStartIndex + windowLength) {
        setWindowStartIndex(clickedIndex - windowLength);
      } else {
        // set bounds for the window index; can't get earlier than 0 or greater than length - window
        setWindowStartIndex(Math.max(0, Math.min(clickedIndex, data.length - windowLength - 1)));
      }
    }
  };

  return (
    <div className="Chart WindowChart">
      <LineChart
        data={windowData}
        {...props}
        xAxisKey={xAxisKey}
        lineWidth="small"
        tooltipLabelKey={xAxisKey}
        yAxisChartDataFields={yAxisChartDataFields}
        referenceAreas={referenceIndices.map((index) => ({
          x1: windowData[index][xAxisKey],
          x2: windowData[index + dataPointsPerDay - 1]?.[xAxisKey],
          fill: GRAY_BACKGROUND,
        }))}
        ticks={referenceIndices.map((index) => windowData[index]?.[xAxisKey]).filter(Boolean)}
        tickFormatter={(val) => dayjs(val).format("ddd, M/D")}
        tooltipLabelFormatter={(val) => dayjs(val).format("ddd, M/D h:mma")}
      />
      <ResponsiveContainer width="100%" height={48}>
        <RechartsAreaChart
          onClick={onClickReferenceChart}
          data={data}
          margin={{ top: 8, right: 0, left: 30, bottom: 0 }}
          style={{ cursor: "pointer" }}
        >
          <XAxis
            id="test"
            dataKey={xAxisKey}
            strokeWidth={0}
            padding={{ left: 0, right: 0 }}
            height={0}
            ticks={[]}
          />

          <Area
            id="full-area"
            type="monotone"
            dataKey={yAxisChartDataFields[0]?.key}
            fill={GRAY_BACKGROUND}
            stroke="none"
            stackId="1"
            name="value"
            animationDuration={ChartAnimationDurations.SHORT}
          />
          <ReferenceArea
            fill={ChartColors.TEAL_CAMBIO}
            fillOpacity={0.3}
            ifOverflow="hidden"
            x1={data[windowStartIndex][xAxisKey]}
            x2={data[windowStartIndex + windowLength - 1][xAxisKey]}
            shape={(props) => {
              const sizeProps = pick(props, "x", "width", "y", "height");

              return (
                <g>
                  <clipPath id="full-area-clip">
                    <use href="#full-area" />
                  </clipPath>
                  <rect {...sizeProps} {...pick(props, "fill", "fillOpacity")}></rect>
                  {/**
                   * This is awesome. We use the main path to clip the viewable area of this rect,
                   * so it looks like a perfect overlay.
                   */}
                  <rect
                    clipPath="url(#full-area-clip)"
                    {...sizeProps}
                    fill={ChartColors.TEAL_CAMBIO}
                  ></rect>
                  <rect {...sizeProps} fill={ChartColors.TEAL_DARK} width={1} />
                  <rect
                    {...sizeProps}
                    fill={ChartColors.TEAL_DARK}
                    x={props.x + props.width - 1}
                    width={1}
                  />
                </g>
              );
            }}
            animationDuration={ChartAnimationDurations.SHORT}
          />
        </RechartsAreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default WindowChart;
