import { useRouter as UseNextRouter } from "next/router";

import useScreenshotContext, { ScreenshotContextType } from "./useScreenshotContext";

// Custom useRouter. It is currently just used to allow the next js router query to be overridden in the screenshot context
const useRouter = () => {
  const router = UseNextRouter();
  const screenshotContext = useScreenshotContext();
  const query = (screenshotContext as ScreenshotContextType)?.query || {};

  return { ...router, query: { ...router.query, ...query } };
};

export default useRouter;
